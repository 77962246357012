import React from "react"

import Layout from "../components/layout-static"
import Seo from "../components/seo"

import { Link } from "gatsby"

const Page = () => {
  const metadata = {
    title: "Pursuing a wrongful death claim after a fatal accident",
    heroH1: "Pursuing a wrongful death claim after a fatal accident",
    heroImage: "blg-van-accident.jpg",
    heroImageAlt:
      "Photo of an automobile accident involving a van and a bicycle",
  }
  return (
    <Layout metadata={metadata}>
      <Seo title={metadata.title} />
      <div
        id="content"
        className="flex flex-wrap container mx-auto px-4 xl:px-0"
      >
        <div className="w-full pt-8 xl:pt-0">
          <div className="mb-16">
            <p className="mb-8">
              <strong>September 20, 2022</strong> by Frank Bartlett
            </p>

            <h2>Pursuing a wrongful death claim after a fatal accident</h2>
            <p className="mb-8">
              Car accidents occur every day in Connecticut. While many are lucky
              to walk away from a car crash relatively unscathed, others will
              ultimately lose their lives after being hit by a car. The loved
              ones of those killed in a fatal accident must figure out how to
              move on, and financially, this can be extremely challenging. Some
              people who find themselves in this situation choose to pursue a
              wrongful death lawsuit.
            </p>

            <h2>What is a wrongful death lawsuit?</h2>
            <p className="mb-8">
              <a
                href="https://www.cga.ct.gov/current/pub/chap_925.htm"
                target="_blank"
                rel="noreferrer"
              >
                Under Connecticut law
              </a>
              , the executor or administrator of the estate can file a wrongful
              death lawsuit on behalf of the deceased’s survivors. In a wrongful
              death suit, you are claiming that your loved one died due to
              another person’s negligence, and your loved one has suffered
              damages as a result. Essentially, a wrongful death suit stands in
              place of the personal injury lawsuit your loved one would have
              filed had they survived the incident.
            </p>

            <h2>What damages can be sought in a wrongful death lawsuit?</h2>
            <p className="mb-8">
              There are a variety of damages that the executor or administrator
              can seek in a wrongful death claim. One is for medical expenses
              incurred prior to death. Another is funeral and burial expenses.
              The estate can also seek compensation for the lost earning
              capacity of the loved one, and their lost opportunity to enjoy
              holidays, birthdays and other life events.
            </p>

            <p className="mb-8">
              Damages for loss of consortium pursued by a spouse or minor child
              is a separate claim from that of a wrongful death suit. Loss of
              consortium includes damages for the loss of companionship, advice,
              affection and moral support of the deceased.
            </p>

            <h2>The statute of limitations</h2>

            <p className="mb-8">
              You do have a time limit to pursue a wrongful death suit. You must
              file one within two years of the victim’s death. This is known as
              a “statute of limitations.” Once this time period is up, you can
              no longer pursue a lawsuit even though you may have had grounds
              for doing so.
            </p>

            <p className="mb-8">
              <Link to="/ct-wrongful-death/">
                Pursuing a wrongful death claim
              </Link>{" "}
              can be complicated, especially for those who have never done so
              before. Many people who lose a loved one in a fatal accident
              choose to consult a lawyer experienced in these types of claims,
              so they can move forward through the process with confidence. At
              Bartlett &amp; Grippe, LLC, we have successfully handled numerous
              wrongful death claims and are here to help families who have lost
              a loved one navigate their wrongful death claim.
            </p>
          </div>
        </div>

      </div>
    </Layout>
  )
}

export default Page
